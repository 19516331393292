import { Grid, Typography, IconButton, Box, Button } from "@mui/material";
import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();

  // Navigation functions
  const goToPage = (path) => navigate(path);

  // Render Quick Links
  const renderQuickLinks = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Typography sx={{ color: "powderblue" }}>Quick Links</Typography>
      <Button
        onClick={() => goToPage("/project")}
        sx={{
          marginTop: "20px",
          color: "white",
          bgcolor: "transparent",
          "&:hover": { bgcolor: "transparent" },
        }}
      >
        Project
      </Button>
      <Button
        onClick={() => goToPage("/achievements")}
        sx={{
          color: "white",
          bgcolor: "transparent",
          "&:hover": { bgcolor: "transparent" },
        }}
      >
        Achievements
      </Button>
      <Button
        onClick={() => goToPage("/contact")}
        sx={{
          color: "white",
          bgcolor: "transparent",
          "&:hover": { bgcolor: "transparent" },
        }}
      >
        Contact Me
      </Button>
    </Box>
  );

  // Render Social Links
  const renderSocialLinks = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        color: "powderblue",
      }}
    >
      <Typography sx={{ paddingBottom: "10px" }}>Social</Typography>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <a
          href="https://www.linkedin.com/in/gauravs-2k/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "inherit" }}
        >
          <IconButton>
            <LinkedInIcon />
          </IconButton>
        </a>
        <a
          href="https://github.com/Gauravs-2k"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "inherit" }}
        >
          <IconButton>
            <GitHubIcon />
          </IconButton>
        </a>
      </Box>
    </Box>
  );

  // Render Footer
  return (
    <Grid
      container
      spacing={2}
      sx={{
        alignItems: 'center',
        backgroundColor: "#333",
        padding: "40px 20px",
      }}
      justifyContent="space-between"
    >
      {/* Left Section */}
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          color: "white",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
        }}
      >
        <Button
          onClick={() => goToPage("/")}
          sx={{
            color: "lightblue",
            fontSize: "25px",
            justifyContent: "left",
          }}
        >
          <b>Gaurav</b>
        </Button>
        <Typography sx={{ fontSize: "14px", paddingTop: "10px",  }}>
          Master’s student in Computer Science at Illinois Institute of
          Technology with a background in software development and
          entrepreneurship. I have founded Bytecrafts, led projects in
          healthcare, education, and finance, and developed innovative solutions
          like real-time sign language translation and automated plant robots.
          My passion lies in creating impactful technology solutions that
          improve lives.
        </Typography>
        <Typography
          sx={{ paddingTop: "20px", fontSize: "12px", color: "white" }}
        >
          Designed and Developed by <a style={{color: 'white'}} href="https://www.bytecrafts.in/">ByteCrafts</a>.
        </Typography>
      </Grid>

      {/* Right Section */}
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          color: "white",
        }}
      >
        <Grid container spacing={2}>
          {/* Quick Links Section */}
          <Grid item xs={4}>
            {renderQuickLinks()}
          </Grid>

          {/* Social Links Section */}
          <Grid item xs={4}>
            {renderSocialLinks()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
