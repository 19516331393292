import React, { useState } from "react";
import {
  AppBar,
  Button,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import LightModeIcon from "@mui/icons-material/LightMode";
import FloatingBar from "./components/FloatingBar";

const Header = () => {
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const isMobileView = useMediaQuery("(max-width:600px)");

  function goToHome() {
    navigate("/");
  }

  function goToContactPage() {
    navigate("/contact");
    setIsDrawerOpen(false);
  }
  function goToProject() {
    navigate("/project");
    setIsDrawerOpen(false);
  }
  function goToAchievements() {
    navigate("achievements");
    setIsDrawerOpen(false);
  }

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const openLinkedInProfile = () => {
    window.open("https://www.linkedin.com/in/gauravs-2k/", "_blank");
  };
  const openGitHubProfile = () => {
    window.open("https://github.com/Gauravs-2k", "_blank");
  };

  return (
    <Grid
      container
      spacing={2}
      style={{
        paddingBottom: "10px",
        backgroundColor: isDarkMode ? "#333" : "#f0f0f0",
      }}
    >
      <Grid item xs={12}>
        <AppBar
          style={{
            backgroundColor: "transparent",
            boxShadow: "none",
            position: "sticky",
          }}
        >
          <Toolbar>
            {isMobileView && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            )}
              <Button
                onClick={goToHome}
                style={{
                  alignSelf: 'center',
                  fontSize: "25px",
                  textAlign: "center",
                  color: isDarkMode ? "lightblue" : "black",
                  fontFamily: "times",
                }}
              >
                <b>Gaurav's Portfolio</b>
              </Button>
            {/* <div style={{ marginLeft: "auto" }}>
              {" "}
              {!isMobileView && (
                <>
                  <Button
                    onClick={goToProject}
                    style={{ color: isDarkMode ? "white" : "black" }}
                  >
                    PROJECT
                  </Button>
                  <Button
                    onClick={goToAchievements}
                    style={{ color: isDarkMode ? "white" : "black" }}
                  >
                    ACHIEVEMENTS
                  </Button>
                  <Button
                    onClick={goToContactPage}
                    style={{ color: isDarkMode ? "white" : "black" }}
                  >
                    Contact Me
                  </Button>
                  <IconButton
                    style={{
                      color: isDarkMode ? "white" : "black",
                      marginLeft: "20px",
                    }}
                    onClick={openLinkedInProfile}
                  >
                    <LinkedInIcon />
                  </IconButton>
                  <IconButton
                    style={{
                      color: isDarkMode ? "white" : "black",
                      marginLeft: "20px",
                    }}
                    onClick={openGitHubProfile}
                  >
                    <GitHubIcon />
                  </IconButton>
                  {/* <IconButton
                    style={{
                      color: isDarkMode ? "white" : "black",
                      marginLeft: "25px",
                    }}
                    onClick={toggleTheme}
                  >
                    {isDarkMode ? <DarkModeIcon /> : <LightModeIcon />}
                  </IconButton>
                </>
              )}
            </div> */}
          </Toolbar>
        </AppBar>
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={handleDrawerToggle}
          PaperProps={{
            sx: {
              position: "fixed",
              top: "6%", // Adjust top positioning
              left: 0, // Anchor it to the left as the drawer should be on the left
              width: "34vw",
              height: "80vh",
              backgroundColor: isDarkMode ? "black" : "#f0f0f0",
              color: isDarkMode ? "white" : "black",
            },
          }}
          ModalProps={{
            keepMounted: true, // Keeps the drawer in the DOM when it is closed
          }}
        >
          <List style={{ display: "flex", flexDirection: "column" }}>
            <ListItem button onClick={goToHome}>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button onClick={goToProject}>
              <ListItemText primary="Project" />
            </ListItem>
            <ListItem button onClick={goToAchievements}>
              <ListItemText primary="Achievements" />
            </ListItem>

            <ListItem button onClick={goToContactPage}>
              <ListItemText primary="Contact Me" />
            </ListItem>
            <ListItem style={{ marginLeft: "10px" }}>
              <LinkedInIcon />
            </ListItem>
            <ListItem style={{ marginLeft: "10px" }}>
              <GitHubIcon />
            </ListItem>
            {/* <ListItem>
              <IconButton
                onClick={toggleTheme}
                style={{ color: isDarkMode ? "white" : "black" }}
              >
                {isDarkMode ? <DarkModeIcon /> : <LightModeIcon />}
              </IconButton>
            </ListItem> */}
          </List>
        </Drawer>
      </Grid>
      {!isMobileView ? <FloatingBar /> : null}
    </Grid>
  );
};

export default Header;
