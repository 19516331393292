import React from "react";
import { Grid, Typography, Card, Button } from "@mui/material";

function Achievements() {
  const publication = [
    {
      title: "Automated public transport system using IoT",
      info: "The adaption of new technologies and application of IoT has solved many critical problems. Create an efficient and data-driven ecosystem. This paper aims to provide commuters with an organized, efficient, and cashless public transportation system. This work aims to give commuters a clear idea about the arrival and departure of public buses. It also makes the payment system efficient, effortless, and cashless. The buses equipped with this technology will provide more options to plan organized trips. Fleets will experience less disorganization due to the data-driven technology they will use.",
      link: "https://link.springer.com/chapter/10.1007/978-981-16-3097-2_28",
    },
    {
      title: "Automated Plant Robot",
      info: "This work is a Raspberry Pi-based prototype that has been built while keeping both the Indian agricultural scenario and hobbyist backyard gardeners in mind. It aims not only to act as a solution to conventional method of growing plants but also to approach along the lines of sustainable development. With our project, we are also aiming to optimize the amount of resources required to grow a plant. The model acts as the brain of the plant by providing directions to the technical implementations of the setup such as an attached pair of wheels and a water pump to act according to the requirement. This prototype could be a possible solution to majority of the urban population planning to have potted plants and greenery in their apartment but with space, time and effort constraints. Further details like implementation, advantages and future are mentioned in the next sections.",
      link: "https://link.springer.com/chapter/10.1007/978-981-15-4218-3_11",
    },
  ];
  const achievements = [
    {
      ach: "Crack the COVID-19 Crisis (March 2021)",
      inf: "Top 16 finalists out of nearly 4000 teams in Hackathon Nascoom, IBM, India",
    },
    {
      ach: "Solve4Bharath (September 2020)",
      inf: "Finalists at hackathon held by Indian Institute of Science IISc, Bangalore, India",
    },
    {
      ach: "MLH Build Day (August 2019)",
      inf: "Winner of Best Innovation hack, Vellore Institute of Technology Amaravathi, India",
    },
  ];

  const handleButtonClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        style={{
          background: "#333",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Grid
          style={{
            height: "800px",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <svg
            width="100%"
            height="100%"
            style={{
              position: "absolute",
              top: "100px",
              left: 0,
              zIndex: 1,
            }}
          >
            <path
              d="M 0 300 Q 400 400 800 300 T 1600 300 L 1600 512 L 0 512 Z"
              fill="black"
              stroke="none"
            />
          </svg>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{
              position: "absolute",
              paddingTop: "40px",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 2,
              fontFamily: "times",
              textAlign: "center",
              color: "#FF00FF",
            }}
          >
            <h1>ACHIEVEMENTS</h1>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="center"
          spacing={4}
          style={{
            position: "relative",
            zIndex: 2,
            marginTop: "-190px",
            backgroundColor: "black",
            paddingBottom: "40px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Typography
            style={{ color: "#6A5ACD", fontSize: "25px", paddingTop: "10px" }}
          >
            <b>PUBLICATIONS</b>
          </Typography>
          {publication.map((pub, index) => (
            <Grid
              item
              xs={12}
              key={index}
              style={{ width: "100%", maxWidth: "700px" }}
            >
              <Card
                style={{
                  padding: "20px",
                  textAlign: "center",
                  borderRadius: "20px",
                  backgroundColor: "#333",
                  border: "1px solid white",
                }}
              >
                <Typography
                  style={{
                    fontSize: "25px",
                    color: "powderblue",
                    paddingBottom: "10px",
                  }}
                >
                  {pub.title}
                </Typography>
                <Typography>{pub.info}</Typography>
                <Button onClick={() => handleButtonClick(pub.link)}>
                  Read More
                </Button>
              </Card>
            </Grid>
          ))}
          <Typography
            style={{ color: "#6A5ACD", fontSize: "25px", paddingTop: "10px" }}
          >
            <b>ACHIEVEMENTS</b>
          </Typography>
          {achievements.map((achi, key) => (
            <Grid
              item
              xs={12}
              key={key}
              style={{ width: "100%", maxWidth: "700px" }}
            >
              <Card
                style={{
                  padding: "20px",
                  textAlign: "center",
                  borderRadius: "20px",
                  backgroundColor: "#333",
                  border: "1px solid white",
                }}
              >
                <Typography
                  style={{
                    fontSize: "25px",
                    color: "powderblue",
                    paddingBottom: "10px",
                  }}
                >
                  {achi.ach}
                </Typography>
                <Typography>{achi.inf}</Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Achievements;
