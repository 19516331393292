import React from 'react';
import { 
  Grid, 
  Typography, 
  useMediaQuery, 
  useTheme, 
  Container, 
  Box 
} from '@mui/material';

const HomeHeader = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        position: 'relative',
        height: '510px',
        overflow: 'hidden',
        width: '100vw',
        bgcolor: theme.palette.background.default,
      }}
    >
      {/* Wave SVG Background */}
      <Box
        component="svg"
        viewBox="0 0 1600 512"
        preserveAspectRatio="none"
        sx={{
          position: 'absolute',
          bottom: -60,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 1,
          '& path': {
            // fill: theme.palette.primary.main,
          },
        }}
      >
        <path d="M 0 300 Q 400 400 800 300 T 1600 300 L 1600 512 L 0 512 Z" />
      </Box>

      <Container maxWidth="lg" sx={{ height: '100%' }}>
        <Grid
          container
          spacing={4}
          sx={{
            position: 'relative',
            zIndex: 2,
            height: '100%',
            alignItems: 'center',
            pt: 4,
          }}
        >
          {/* Text Content */}
          <Grid item xs={12} md={6}>
            <Box sx={{ color: 'white' }}>
              <Typography
                variant="h1"
                component="h1"
                sx={{
                  fontFamily: 'times',
                  fontSize: isMobile ? '1.8rem' : '3.2rem',
                  mb: 3,
                }}
              >
                Hi there!
                <br /> I'm Gaurav Shivaprasad...✨
              </Typography>

              <Typography sx={{ mb: 2 }}>
                A dedicated graduate student pursuing a Master's in Computer Science
                at the Illinois Institute of Technology.
              </Typography>

              <Typography sx={{ mb: 2 }}>
                Passionate about developing impactful software solutions and collaborating
                with teams to solve real-world problems using cutting-edge technology.
              </Typography>

              <Typography>
                I am a tech enthusiast with a strong background in software engineering
                and mobile application development.
              </Typography>
            </Box>
          </Grid>

          {/* Image - Hidden on mobile */}
          {!isMobile && (
            <Grid 
              item 
              md={6}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                component="img"
                src="gaurav-org.webp"
                alt="Gaurav Shivaprasad"
                sx={{
                  height: '360px',
                  width: '450px',
                  objectFit: 'cover',
                  borderRadius: theme.shape.borderRadius,
                  boxShadow: theme.shadows[4],
                }}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default HomeHeader;