import React from "react";

import {
  IconBrandGithub,
  IconTrophy,
  IconBriefcase,
  IconHome,
  IconPhone,
  IconTerminal2,
  IconBrandLinkedin,
} from "@tabler/icons-react";
import { FloatingDock } from "../ui/FloatingDoc";
const FloatingBar = () => {
  const links = [
    {
      title: "Home",
      icon: (
        <IconHome className="h-full w-full text-neutral-500 dark:text-neutral-300" />
      ),
      href: "https://www.gauravshivaprasad.com/",
    },

    {
      title: "Projects",
      icon: (
        <IconTerminal2 className="h-full w-full text-neutral-500 dark:text-neutral-300" />
      ),
      href: "https://www.gauravshivaprasad.com/project",
    },
    {
      title: "Work",
      icon: (
        <IconBriefcase className="h-full w-full text-neutral-500 dark:text-neutral-300" />
      ),
      href: "https://www.gauravshivaprasad.com/work",
    },
    {
      title: "Awards & Achievements",
      icon: (
        <IconTrophy className="h-full w-full text-neutral-500 dark:text-neutral-300" />
      ),
      href: "https://www.gauravshivaprasad.com/achievements",
    },
    {
      title: "Contact Me",
      icon: (
        <IconPhone className="h-full w-full text-neutral-500 dark:text-neutral-300" />
      ),
      href: "https://www.gauravshivaprasad.com/contact",
    },

    {
      title: "Twitter",
      icon: (
        <IconBrandLinkedin className="h-full w-full text-neutral-500 dark:text-neutral-300" />
      ),
      href: "https://www.linkedin.com/in/gauravs-2k/",
    },
    {
      title: "GitHub",
      icon: (
        <IconBrandGithub className="h-full w-full text-neutral-500 dark:text-neutral-300" />
      ),
      href: "https://github.com/Gauravs-2k",
    },
  ];

  return (
    <div
      style={{
        position: "fixed", // Keeps it fixed at the bottom
        bottom: 20, // Aligns it to the bottom of the screen
        left: 0,
        width: "100%", // Spans the full width for centering
        display: "flex", // Enables flexbox for horizontal centering
        justifyContent: "center", // Centers content horizontally
        zIndex: 50, // Ensures it appears above other content
      }}
    >
      <FloatingDock
        mobileClassName="translate-y-20" // Only for demo, remove for production
        items={links}
      />
    </div>
  );
};

export default FloatingBar;
