import { Grid, Typography, Button, Box, useMediaQuery } from "@mui/material";
import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import DownloadSharpIcon from "@mui/icons-material/DownloadSharp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import GitHubIcon from "@mui/icons-material/GitHub";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
function ContactPage() {
  const openLinkedInProfile = () => {
    window.open("https://www.linkedin.com/in/gauravs-2k/", "_blank");
  };

  const openGitHubProfile = () => {
    window.open("https://github.com/Gauravs-2k", "_blank");
  };
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Grid
      container
      style={{
        backgroundColor: "black",
        justifyContent: "space-around",
        paddingBottom: "40px",
      }}
    >
      <Grid item xs={12} sm={6}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid gray",
            paddingLeft: "24px",
            color: "white",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            margin: "0 auto",
            textAlign: "left ",
            height: "300px",
          }}
        >
          <img
            src="Contact.png"
            style={{ width: isMobile ? "100%" : "600px", height: "360px" }}
          ></img>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid gray",
            paddingLeft:isMobile?"5px": "24px",
            color: "white",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            margin: "0 auto",
            textAlign: "left ",
            height:isMobile?"100%": "300px",
          }}
        >
          <Typography>
            Welcome to our contact page. Whether you have questions, quries or
            just want to say hello. we'd love to hear from you. Reach out using
            the detailes below or simply fill out the contact form and we'll get
            back to you soon.
          </Typography>
        </Box>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        style={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}
      >
        <Grid
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
         
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid gray",
              color: "white",
              margin: "0 auto",
              textAlign: "center",
              height: "80px",
              width: isMobile ? "100%" : "600px",
              margin: isMobile ? "0 auto 16px auto" : "0 auto",
              maxWidth: "380px",
            }}
          >
            <Button
              component="a"
              href="mailto:shivaprasad.gaurav@gmail.com"
              sx={{
                display: "flex",
                alignItems: "center",
                background: "black",
                color: "white",
                border: "1px solid white",
                padding: "4px 8px",
                textDecoration: "none",

              }}
            >
              <EmailIcon sx={{ marginRight: "8px" }} />
              shivaprasad.gaurav@gmail.com
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid gray",
              color: "white",
              margin: "0 auto",
              textAlign: "center",
              height: "80px",
              width: isMobile ? "100%" : "606px",
              margin: isMobile ? "0 auto 16px auto" : "0 auto",
            }}
          >
            <Button
              variant="contained"
              style={{
                display: "flex",
                backgroundColor: "black",
                alignItems: "center",
                color: "white",
                border: "1px solid white",
                padding: "4px 8px",
              }}
            >
              <LocalPhoneIcon />
              +91-6362718708 , +13129188057
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid gray",
              color: "white",
              margin: "0 auto",
              textAlign: "center",
              height: "80px",
              width: isMobile ? "100%" : "100%",
              maxWidth: "375px",
            }}
          >
            <Button
              variant="contained"
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "black",
                color: "white",
                border: "1px solid white",
                padding: "4px 8px",
              }}
            >
              <DownloadSharpIcon />
              Download CV
            </Button>
          </Box>
          {isMobile && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid gray",
                color: "white",
                margin: "0 auto",
                textAlign: "center",
                height: "80px",
                width: "100%",
                maxWidth: "375px",
                marginTop: "16px", 
              }}
            >
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={openLinkedInProfile}
                  variant="contained"
                  style={{
                    color: "white",
                    backgroundColor: "black",
                    border: "1px solid white",
                  }}
                >
                  <LinkedInIcon />
                </Button>
                <Button
                  onClick={openGitHubProfile}
                  variant="contained"
                  style={{
                    color: "white",
                    backgroundColor: "black",
                    border: "1px solid white",
                    marginLeft: "5px",
                  }}
                >
                  <GitHubIcon />
                </Button>
              </Grid>
            </Box>
          )}
        </Grid>
        {!isMobile && (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid gray",
                color: "white",
                margin: "0 auto",
                textAlign: "center",
                height: "80px",
                width: isMobile ? "100%" : "100%",
                maxWidth: "375px",
              }}
            >
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={openLinkedInProfile}
                  variant="contained"
                  style={{
                    color: "white",
                    backgroundColor: "black",
                    border: "1px solid white",
                  }}
                >
                  <LinkedInIcon />
                </Button>
                <Button
                  onClick={openGitHubProfile}
                  variant="contained"
                  style={{
                    color: "white",
                    backgroundColor: "black",
                    border: "1px solid white",
                    marginLeft: "10px",
                  }}
                >
                  <GitHubIcon />
                </Button>
              </Grid>
            </Box>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
        <Typography
          style={{
            fontSize: "40px",
            color: "white",
            marginTop: "30px",
            paddingLeft: "20px",
          }}
        >
          Send Me a Message
        </Typography>
        <Typography style={{ color: "white", paddingLeft: "20px" }}>
          Have a specific inquiry or message for us?
        </Typography>
        <Box
          sx={{
            display: "flex",
            border: "1px solid gray",
            color: "white",
            marginTop: "20px",
            height: "80px",
            width: isMobile ? "100%" : "1490px",
            alignItems: "center",
            paddingLeft:isMobile?"0px": "20px",
          }}
        >
          <Typography
            style={{ color: "white", marginRight: isMobile ? "0px" : "40px" }}
          >
            Hey, my name is
          </Typography>
          <TextField
            id="standard-basic"
            label="Your Name"
            variant="standard"
            InputProps={{ style: { color: "white" } }}
          />
          <Typography style={{ marginLeft: isMobile ? "0px" : "20px" }}>
            and I'm looking for
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            border: "1px solid gray",
            color: "white",
            marginTop: "20px",
            height: isMobile ? "100%" : "80px",
            width: isMobile ? "100%" : "1490px",
            alignItems: "center",
            paddingLeft:isMobile?"0px": "20px",
          }}
        >
          <Typography style={{ color: "white", marginRight: "40px" }}>
            Get in touch with me at
          </Typography>
          <TextField
            id="standard-basic"
            label="Your Email Here"
            variant="standard"
            InputProps={{ style: { color: "white" } }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default ContactPage;
