import React, { useState } from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  Card,
  Button,
  Box,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HomeHeader from "./HomeComponents.jsx/HomeHeader";
import { useNavigate } from "react-router-dom";
import { BackgroundGradient } from "./ui/BackgroundGradient";
import { Modal, ModalTrigger } from "./ui/ModalAnimation";

export default function HomePage() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const cards = [
    {
      title: "Publications",
      image: "Publication.jpg",
      points: [
        "Automated public transport system using IoT",
        "Automated Plant Robot",
      ],
      buton: "Read More",
      path: "/achievements",
    },
    {
      title: "Projects",
      image: "Project.jpg",
      points: [
        "Refease",
        "Namnews",
        "SmartASL - Real Time sign language Interpreter",
      ],
      buton: "Read More",
      path: "project",
    },
    {
      title: "Awards and Achievements",
      image: "Awards.jpg",
      points: [
        "Top 16 finalists out of nearly 4000 teams (March 2021)",
        "Winner of the Best Innovation hack on MLH Build Day (August 2019).",
        "Finalist at Solve4Bharath Hackathon",
      ],
      buton: "Read More",
      path: "achievements",
    },
    {
      title: "Education ",
      image: "Education.jpg",
      points: [
        "Master’s in Computer Science Illinois Institute of Technology, May 2026",
        "Bachelor of Technology in Computer Science and Engineering Vellore Institute of Technology - AP, May 2022 | GPA: 8.78",
      ],
    },
    {
      title: "WORK EXPERIENCE ",
      image: "Work.jpg",
      points: [
        "Currently working as Hawk’s Nest Graduate Assistant, Part-Time, Illinois Institute of Technology, Chicago, Illinois (September 2024 – Present)",
      ],
      buton: "Know More",
      path: "work",
    },
  ];
  const handleMouseEnter = (index) => {
    setHover((prevHover) => ({ ...prevHover, [index]: true }));
  };

  const handleMouseLeave = (index) => {
    setHover((prevHover) => ({ ...prevHover, [index]: false }));
  };

  const chips = [
    {
      title: "Frontend And Web Development",
      values: [
        {
          name: "MERN",
          icon: "assets/icons/mern.png",
        },
        {
          name: "React",
          icon: "assets/icons/react.png",
        },
        {
          name: "Next.js",
          icon: "assets/icons/next.svg",
        },
        {
          name: "HTML5",
          icon: "assets/icons/html.svg",
        },
        {
          name: "CSS3",
          icon: "assets/icons/css.svg",
        },
        {
          name: "Javascript",
          icon: "assets/icons/js.svg",
        },
        {
          name: "Bootstrap",
          icon: "assets/icons/bootstrap.svg",
        },
        {
          name: "Tailwind CSS",
          icon: "assets/icons/tailwind.svg",
        },
      ],
    },
    {
      title: "Mobile Application Development",
      values: [
        {
          name: "React Native",
          icon: "assets/icons/reactnative.svg",
        },
        {
          name: "Xcode",
          icon: "assets/icons/xcode.svg",
        },
        {
          name: "Flutter",
          icon: "assets/icons/flutter.svg",
        },
        {
          name: "Android Studio",
          icon: "assets/icons/android-studio.svg",
        },
      ],
    },
    {
      title: "Backend And Database Management",
      values: [
        {
          name: "MySQL",
          icon: "assets/icons/mysql.svg",
        },
        {
          name: "Oracle",
          icon: "assets/icons/oracle.svg",
        },
        {
          name: "MongoDB",
          icon: "assets/icons/mongodb.svg",
        },
        {
          name: "Node.js",
          icon: "assets/icons/nodejs.svg",
        },
        {
          name: "Express.js",
          icon: "assets/icons/express.svg",
        },
        {
          name: "PostgreSQL",
          icon: "assets/icons/postgresql-plain.svg",
        },
      ],
    },
    {
      title: "Cloud And DevOps Tools",
      values: [
        {
          name: "AWS",
          icon: "assets/icons/aws.svg",
        },
        {
          name: "Vercel",
          icon: "assets/icons/vercel.svg",
        },
        {
          name: "Render",
          icon: "assets/icons/render.png",
        },
        {
          name: "Microsoft Clarity",
          icon: "assets/icons/microsoft.svg",
        },
        {
          name: "Google cloud platform",
          icon: "assets/icons/google-cloud.svg",
        },
        {
          name: "CI/CD Pipelines",
          icon: "assets/icons/pipline.svg",
        },
      ],
    },
    {
      title: "Programming Languages",
      values: [
        {
          name: "Python",
          icon: "assets/icons/python.svg",
        },
        {
          name: "Javascript",
          icon: "assets/icons/js.svg",
        },
        {
          name: "Java",
          icon: "assets/icons/java.svg",
        },
      ],
    },
    {
      title: "Developer And Collaboration Tools",
      values: [
        {
          name: "Git",
          icon: "assets/icons/git.svg",
        },
        {
          name: "Github",
          icon: "assets/icons/github.svg",
        },
        {
          name: "Jira",
          icon: "assets/icons/jira.svg",
        },
        {
          name: "VS Code",
          icon: "assets/icons/vs-code.svg",
        },
        {
          name: "Slack",
          icon: "assets/icons/slack.svg",
        },
        {
          name: "Trello",
          icon: "assets/icons/trello.svg",
        },
        {
          name: "Confluence",
          icon: "assets/icons/confluence.svg",
        },
        {
          name: "Notion",
          icon: "assets/icons/notion.svg",
        },
        {
          name: "Figma",
          icon: "assets/icons/figma.svg",
        },
        {
          name: "Postman",
          icon: "assets/icons/postman.svg",
        },
      ],
    },
    {
      title: "AI_ML Frameworks",
      values: [
        {
          name: "Tensorflow",
          icon: "assets/icons/tensorflow.svg",
        },
        {
          name: "Keras",
          icon: "assets/icons/keras.svg",
        },
        {
          name: "Pytorch",
          icon: "assets/icons/pytorch.svg",
        },
        {
          name: "OpenCV",
          icon: "assets/icons/opencv.svg",
        },
        {
          name: "scikit-learn",
          icon: "assets/icons/scikit-learn.png",
        },
        {
          name: "Pandas",
          icon: "assets/icons/pandas.svg",
        },
        {
          name: "Numpy",
          icon: "assets/icons/numpy.svg",
        },
      ],
    },
    {
      title: "SoftSkills",
      values: [
        {
          name: "Productive",
          icon: "assets/icons/productive.png",
        },
        {
          name: "Efficient",
          icon: "assets/icons/efficient.png",
        },
        {
          name: "Collaborative",
          icon: "assets/icons/collaborative.png",
        },
        {
          name: "Abstract Thinking",
          icon: "assets/icons/thinking.png",
        },
        {
          name: "Problem-Solving",
          icon: "assets/icons/problem-solving.png",
        },
        {
          name: "Team Leadership",
          icon: "assets/icons/leadership.png",
        },
      ],
    },
    {
      title: "Languages Spoken",
      values: [
        {
          name: "English",
          icon: "assets/icons/english.png",
        },
        {
          name: "Kannada",
          icon: "assets/icons/kannada.png",
        },
        {
          name: "Hindi",
          icon: "assets/icons/hindi.png",
        },
        {
          name: "Telugu",
          icon: "assets/icons/telugu.png",
        },
      ],
    },
  ];

  return (
    <Grid
      container
      style={{ backgroundColor: "#333", justifyContent: "space-around" }}
    >
      {isMobile && (
        <Grid
          style={{
            right: "5%",
            zIndex: 2,
          }}
        >
          <img
            style={{
              height: "260px",
              width: "350px",
            }}
            src="gaurav-org.webp"
            alt="Boy Illustration"
          />
        </Grid>
      )}
      <HomeHeader />

      <Grid container style={{ display: "flex" }}>
        <Grid
          item
          xs={12}
          sm={6}
          md={7.5}
          style={{
            backgroundColor: "black",
            color: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: isMobile ? "10px" : "110px",
            paddingBottom: "40px",
          }}
        >
          {cards.map((item, index) => (
            <Box style={{ marginBottom: "60px" }}>
              <BackgroundGradient className="rounded-[22px] max-w-lg width-lg p-4 sm:p-10 bg-white dark:bg-zinc-900 pb-8">
                <Typography
                  style={{
                    fontSize: "25px",
                    color: "lightblue",
                    textAlign: "center",
                    marginBottom: "20px",
                  }}
                >
                  <b>{item.title}</b>
                </Typography>
                <img
                  style={{
                    borderRadius: "10px",
                    width: isMobile ? "90%" : "350px",
                    height: "200px",
                    display: "block",
                    margin: "0 auto",
                  }}
                  src={item?.image}
                />
                {item.points.map((val, index) => (
                  <Grid
                    container
                    direction="column"
                    alignItems="left"
                    style={{ paddingLeft: "10px", textAlign: "left" }}
                  >
                    <Typography
                      style={{
                        paddingTop: "20px",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {item.points.length > 1 && <ArrowForwardIcon />}
                      {val}
                    </Typography>
                  </Grid>
                ))}
                {item.buton && item.path && (
                  <Button
                    onClick={() => navigate(item.path)}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={() => handleMouseLeave(index)}
                    style={{
                      marginTop: "20px",
                      color: "lightblue",
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: hover ? "transparent" : "transparent",
                      transform: hover[index]
                        ? "translateX(5px)"
                        : "translateX(0)",
                      transition: "transform 0.3s ease",
                    }}
                  >
                    {item.buton}
                  </Button>
                )}
              </BackgroundGradient>
            </Box>
          ))}
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4.5}
          style={{
            color: "white",
            backgroundColor: "black",
            paddingBottom: "50px",
            alignItems: "center",
            paddingLeft: isMobile ? "20px" : "20px",
            paddingTop: "20px",
            width: "100%",
          }}
        >
          {chips.map((value) => (
            <>
              <Typography
                style={{
                  color: "lightblue",
                  fontSize: "25px",
                  paddingTop: "100px",
                }}
              >
                <b>{value.title}</b>
              </Typography>
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "colomn",
                }}
              >
                {value.values.map((vals) => (
                  <Box style={{ margin: "10px" }}>
                    <Modal>
                      <ModalTrigger className="bg-black dark:bg-white dark:text-black text-white flex justify-center group/modal-btn">
                        <span className="group-hover/modal-btn:translate-x-40 text-center transition duration-500">
                          {vals.name}
                        </span>
                        <div className="-translate-x-40 group-hover/modal-btn:translate-x-0 flex items-center justify-center absolute inset-0 transition duration-500 text-white z-20">
                          <img
                            src={vals.icon}
                            style={{
                              width: "40px",
                              height: "40px",
                              objectFit: "fill",
                            }}
                          />
                        </div>
                      </ModalTrigger>
                    </Modal>
                  </Box>
                ))}
              </Grid>
            </>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
