import React from "react";
import { Timeline } from "./ui/Timeline";

const Work = () => {
  const workExperience = [
    {
      designation: "Hawk’s Nest Graduate Assistant",
      location: "Illinois Institute of Technology, Chicago, Illinois",
      title: "Sept 2024",
      content:
        "Working as a Graduate Assistant, supporting various functions within Hawk’s Nest program, providing guidance and support to undergraduate students.",
    },
    {
      designation: "Founder CEO, Bytecrafts",
      location: "Davangere, India",
      title: "Sept 2023",
      content:
        "Founded Bytecrafts, offering technology consulting and development services. Led product development and client management while ensuring a strong project pipeline.",
    },
    {
      designation: "Software Engineer, Kristal.AI",
      location: "Bangalore, India",
      title: "July 2022",
      content:
        "Developed and enhanced mobile applications, focusing on optimizing UI/UX and ensuring cross-platform functionality using React Native and Flutter.",
    },
    {
      designation: "Software Engineer Intern, Kristal.AI",
      location: "Bangalore, India",
      title: "Jan 2022",
      content:
        "Interned as a mobile developer, working on app performance optimization and learning cross-platform development in a real-world environment.",
    },
  ];
  return (
    <>
      <Timeline data={workExperience} />
    </>
  );
};

export default Work;
