import React from "react";
import { Grid, Typography, Card, Box } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
function Project() {
  const proj = [
    {
      image: [
        "projects/pharmit/p1.png",
        "projects/pharmit/p2.png",
        "projects/pharmit/p4.png",
        "projects/pharmit/p5.png",
      ],
      tech_tag: ["React", "MySQL", "node", "Express.js"],
      title: "Hr-Payroll - PharmIT",
      titinfo: [
        "Comprehensive HR and Payroll Integration: Developed a solution that merges the functionalities of Zoho Hiring and Razorpay Payroll, streamlining recruitment and payroll processes.",
        "Automated Hiring Workflow: Implemented automated hiring workflows, including job postings, candidate tracking, and offer management, enhancing recruitment efficiency.",
        "Seamless Payroll Management: Integrated payroll processing with automated salary disbursement, tax calculations, and compliance handling to reduce manual effort.",
        "User-Friendly and Scalable: Built an intuitive, scalable platform that supports growing teams, providing robust data security and compliance features.",
      ],
      link: "https://hrtest.pharmit.in/",
    },
    {
      image: [
        "projects/Refease/RefEase 1.png",
        "projects/Refease/R2.png",
        "projects/Refease/R3.png",
        "projects/Refease/R4.png",
        "projects/Refease/R5.png",
        "projects/Refease/R6.png",
      ],
      title: "Refease - Bytecrafts",
      tech_tag: ["React", "Mongodb", "node", "Express.js"],
      titinfo: [
        "Developed a platform that revolutionizes dental imaging by enabling clinics to capture, store, and share X-rays seamlessly. The solution enhances doctor-patient collaboration and reduces paperwork with a digital-first approach.",
        "Ensured patient data confidentiality through HIPAA compliance and granular access control, safeguarding medical records and images with top-notch security standards.",
        "Facilitated efficient clinic-doctor collaboration by enabling clinics to onboard doctors, manage patient records, and assign X-rays for prompt review, improving the overall workflow.",
        "Upcoming feature includes integrated billing and invoicing, simplifying financial management for clinics and doctors within the platform.",
      ],
      link: "https://www.refease.in/",
    },
    {
      image: [
        "projects/NamNews/N1.png",
        "projects/NamNews/N2.png",
        "projects/NamNews/N3.png",
        "projects/NamNews/N4.png",
        "projects/NamNews/N5.png",
      ],
      title: "NamNews",
      tech_tag: [
        "Next.js",
        "React Native",
        "Xcode",
        "Mongodb",
        "node",
        "Express.js",
      ],
      titinfo: [
        "Developed a scalable digital platform that enables local newspapers to establish an online presence, manage digital content, and expand audience reach through social media integration.",
        "Enhanced visibility and engagement by providing tools for tracking reader activity and analyzing website traffic through comprehensive dashboards.",
        "Created new revenue opportunities for newspapers through subscription models and ad placements, helping generate passive income and sustain operations.",
        "Supported sustainable digital growth by empowering newspapers to build their brand, engage audiences, and mitigate the decline in print readership.",
      ],
      link: "https://www.youtube.com/watch?v=ao3lY84Tbko&t=36s",
    },
    {
      image: [
        "projects/Marketplace/G1.png",
        "projects/Marketplace/G2.png",
        "projects/Marketplace/G3.png",
        "projects/Marketplace/G4.png",
        "projects/Marketplace/G5.png",
        "projects/Marketplace/G6.png",
        "projects/Marketplace/G7.png",
      ],
      title: "GiveMore – Community Marketplace for COVID-19 (January 2019)",
      tech_tag: ["Flutter", "Mongodb"],
      titinfo: [
        "Designed and developed a cross-platform mobile application using Flutter and Firebase to connect donors with the financially underprivileged, medical workers, NGOs, and hospitals.",
        "Implemented real-time data synchronization, user authentication, and inventory management.",
        "Integrated an IBM Watson virtual assistant to provide users with instant answers to COVID-related queries and assist distributors with optimized navigation routes.",
        "Secured a Top 16 finalist position out of 26,478 participants in a national competition, showcasing innovation and community impact.",
        "The platform facilitated safe and efficient distribution of essential supplies, promoted temporary employment opportunities, and adhered to social distancing guidelines, contributing significantly to community resilience during the pandemic.",
      ],
      link: "https://www.youtube.com/watch?v=ao3lY84Tbko&t=36s",
    },
  ];

  return (
    <Grid container>
      
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        style={{ backgroundColor: "#333", position: "relative", zIndex: 1 }}
      >
        <Grid
        container
        sx={{
        
          position: 'relative',
          zIndex: 2,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h1"
          component="h1"
          sx={{
            fontFamily: 'times',
            color: '#FFFFFF',
            textAlign: 'center',
            fontSize: { xs: '3rem', md: '4rem' },
            fontWeight: 'bold',
          }}
        >
          PROJECT
        </Typography>
      </Grid>
       <Box
      sx={{
        width: '100vw',
        height: '100vh',
        position: 'relative',
        overflow: 'hidden',
        marginLeft: 'calc(-50vw + 50%)',
        marginRight: 'calc(-50vw + 50%)',
        bgcolor: 'background.default',
      }}
    >
      {/* Wave SVG Background */}
      <Box
        component="svg"
        viewBox="0 0 1600 512"
        preserveAspectRatio="none"
        sx={{
          position: 'absolute',
          top: '100px',
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 1,
        }}
      >
        <path
          d="M0 300Q400 350 800 300T1600 300V512H0z"
          fill="black"
          stroke="none"
        />
      </Box>

      {/* Content */}
      
    </Box>
        <Grid
          container
          direction="column"
          alignItems="center"
          spacing={4}
          style={{
            position: "relative",
            zIndex: 2,
            marginTop: "-190px",
            backgroundColor: "black",
            paddingBottom: "40px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          {proj.map((por, index) => (
            <Grid
              container
              item
              xs={12}
              key={index}
              spacing={4}
              style={{
                width: "100%",
                maxWidth: "1200px",
                display: "flex",
                flexDirection: index % 2 === 0 ? "row" : "row-reverse",
              }}
            >
              <Grid item xs={12} sm={6} style={{ paddingRight: "20px" }}>
                <Carousel
                  showStatus={false}
                  infiniteLoop
                  useKeyboardArrows
                  dynamicHeight
                  autoPlay={true}
                  showThumbs={false}
                >
                  {por?.image?.map((img) => (
                    <div
                      key={index}
                      style={{ borderRadius: "10px", overflow: "hidden" }}
                    >
                      <img
                        style={{
                          width: "500px",
                          height: "450px",
                          borderRadius: "10px",
                          objectFit: "contain",
                        }}
                        src={img}
                      />
                      <p className="legend">{por.title}</p>{" "}
                    </div>
                  ))}
                </Carousel>
              </Grid>
              <Grid item xs={12} sm={6} style={{ paddingLeft: "20px" }}>
                <Card
                  style={{
                    padding: "20px",
                    textAlign: "start",
                    borderRadius: "20px",
                    backgroundColor: "#333",
                    border: "1px solid white",
                  }}
                >
                  <Typography
                    style={{
                      color: "powderblue",
                      marginBottom: "10px",
                      fontSize: "20px",
                    }}
                  >
                    {por.title}
                  </Typography>
                  {por.tech_tag.map((tag) => (
                    <div
                      style={{
                        display: "inline-block",
                        padding: "5px 10px",
                        backgroundColor: "#e0e0e0",
                        borderRadius: "15px",
                        margin: "5px",
                        fontSize: "12px",
                        color: "#333",
                      }}
                    >
                      {tag}
                    </div>
                  ))}
                  <ul>
                    {por.titinfo.map((info, i) => (
                      <li key={i}>{info}</li>
                    ))}
                  </ul>

                  <a
                    style={{
                      textAlign: "start",
                      color: "lightBlue",
                      textDecoration: "none",
                    }}
                    href={por.link}
                  >
                    Know More.....
                  </a>
                </Card>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Project;
